import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, merge, of } from 'rxjs';
import { map, exhaustMap, catchError, tap, switchMap, distinctUntilChanged, concatMap, distinctUntilKeyChanged } from 'rxjs/operators';

import { NotificationApiActions, NotificationUiActions } from './notification.actions';
import { NotificationService } from './notification.service';

@Injectable()
export class NotificationEffects {
  findAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.findAllTriggered),
      switchMap(({ filter }) => {
        return this.notificationSrv.findMany({ ...filter }).pipe(
          map(({ data, errors }) =>
            data
              ? NotificationApiActions.findAllSucceeded({ items: data.notifications.edges.map(e => e.node) })
              : NotificationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });

  select$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.select),
      switchMap(({ id }) => {
        return this.notificationSrv.findOne(id!).pipe(
          map(({ data, errors }) =>
            data
              ? NotificationApiActions.findOneSucceeded({ item: data.notification })
              : NotificationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });

  observe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.findAllTriggered),
      switchMap(({ filter }) => {
        const created$ = this.notificationSrv
          .observeCreated(filter)
          .pipe(map(created => NotificationApiActions.created({ item: created })));
        const updated$ = this.notificationSrv
          .observeUpdated(filter)
          .pipe(map(updated => NotificationApiActions.updatedOne({ item: updated })));
        const deleted$ = this.notificationSrv
          .observeDeleted(filter)
          .pipe(map(deleted => NotificationApiActions.deletedOne({ id: deleted.id! })));

        return merge(created$, updated$, deleted$);
      })
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.createTriggered),
      concatMap(p =>
        this.notificationSrv.create(p.input).pipe(
          map(({ data, errors }) =>
            data
              ? NotificationApiActions.createSucceeded({ id: data.createOneNotification.id })
              : NotificationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.updateOneTriggered),
      concatMap(p =>
        this.notificationSrv.update(p.id, p.update).pipe(
          map(({ data, errors }) =>
            data
              ? NotificationApiActions.updateOneSucceeded({ id: data.updateOneNotification.id })
              : NotificationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationUiActions.deleteOneTriggered),
      concatMap(p =>
        this.notificationSrv.delete({ id: p.id }).pipe(
          map(({ data, errors }) =>
            data
              ? NotificationApiActions.deleteOneSucceeded({ id: data.deleteOneNotification.id! })
              : NotificationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  onCreateOrUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationApiActions.createSucceeded, NotificationApiActions.updateOneSucceeded),
        tap(({ id }) => {
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          this.router.navigate(['/admin/notifications', id]);
        })
      );
    },
    { dispatch: false }
  );

  onDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationApiActions.deleteOneSucceeded),
        tap(({ id }) => {
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          this.router.navigate(['/admin/notifications']);
        })
      );
    },
    { dispatch: false }
  );

  onError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationApiActions.requestFailed),
        tap(({ errors }) => {
          console.error(errors);
          this.msgSrv.error(this.translateSrv.instant('COMMON.ERROR.unexpected'));
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationSrv: NotificationService,
    private msgSrv: NzMessageService,
    private translateSrv: TranslateService,
    private router: Router
  ) {}
}
