/*
 * Automatically generated by 'ng g ng-alain:plugin icon'
 */

import {
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  BankOutline,
  CheckCircleTwoTone,
  CodeOutline,
  ColumnHeightOutline,
  ColumnWidthOutline,
  CopyrightOutline,
  ExclamationCircleTwoTone,
  FileAddTwoTone,
  FullscreenExitOutline,
  FullscreenOutline,
  LockOutline,
  LogoutOutline,
  PrinterOutline,
  RobotOutline,
  ScissorOutline,
  SendOutline,
  SettingOutline,
  SoundOutline,
  ToolOutline,
  UndoOutline,
  UnlockOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';

export const ICONS_AUTO = [
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ExclamationCircleTwoTone,
  BankOutline,
  CheckCircleTwoTone,
  CodeOutline,
  ColumnHeightOutline,
  ColumnWidthOutline,
  CopyrightOutline,
  FileAddTwoTone,
  FullscreenExitOutline,
  FullscreenOutline,
  LockOutline,
  LogoutOutline,
  PrinterOutline,
  RobotOutline,
  ScissorOutline,
  SendOutline,
  SettingOutline,
  SoundOutline,
  ToolOutline,
  UndoOutline,
  UnlockOutline,
  UserOutline
];
