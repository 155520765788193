import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, select } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { NotificationConfirm } from 'src/app/graphql/frontend-data-graphql';

import { NotificationConfirmUiActions, NotificationConfirmApiActions } from './notification-confirm.actions';

export interface NotificationConfirmState extends EntityState<NotificationConfirm> {
  selectedId: string | null;
  count: number | null;
  errors: readonly GraphQLError[];
}

export const notificationConfirmAdapter: EntityAdapter<NotificationConfirm> = createEntityAdapter<NotificationConfirm>({
  selectId: p => p.id,
  // sortComparer: (a, b) => a.created.getTime() - b.created.getTime()
  sortComparer: false
});

const state: NotificationConfirmState = notificationConfirmAdapter.getInitialState({ selectedId: null, count: null, errors: [] });

export const notificationConfirmReducer = createReducer<NotificationConfirmState>(
  state,
  on(NotificationConfirmUiActions.select, (state, { id }): NotificationConfirmState => {
    return { ...state, selectedId: id };
  }),
  on(NotificationConfirmApiActions.findAllSucceeded, (state, { items }): NotificationConfirmState => {
    return notificationConfirmAdapter.upsertMany(items, { ...state, errors: [] });
  }),
  on(NotificationConfirmApiActions.countUnreadSucceeded, (state, { count }): NotificationConfirmState => {
    return { ...state, count, errors: [] };
  }),
  on(NotificationConfirmApiActions.updatedOne, (state, { item }): NotificationConfirmState => {
    let count = state.count ?? 0;
    if (state.entities[item.id]) {
      count = count + +state.entities[item.id]!.confirmed - +item.confirmed;
    } else {
      count = count + +!item.confirmed;
    }
    return notificationConfirmAdapter.addOne(item, {
      ...state,
      count: count
    });
  }),
  on(NotificationConfirmApiActions.markAsReadSucceeded, (state): NotificationConfirmState => {
    return notificationConfirmAdapter.map(nc => ({ ...nc, confirmed: true }), { ...state, errors: [], count: 0 });
  }),
  on(NotificationConfirmApiActions.requestFailed, (state, { errors }): NotificationConfirmState => {
    return notificationConfirmAdapter.removeAll({ ...state, selectedId: null, errors });
  })
);
